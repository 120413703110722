var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"language_content relative mr-4 p-6 mt-4 border border-8 border-grey rounded"},[(_vm.language)?_c('div',[_c('div',{staticClass:"flex flex-row justify-between items-baseline"},[_c('h6',[_vm._v(_vm._s(_vm.language.name))]),_c('p',{staticClass:"text-right text-sm"},[_vm._v(_vm._s(_vm.content.count)+" "+_vm._s(this.$t('apps.campaigns.section_content_devices')))])]),(_vm.language.is_default)?_c('p',{staticClass:"text-sm"},[_vm._v(_vm._s(this.$t('apps.campaigns.section_content_default_language')))]):_c('div',[(this.campaign_languages_is_default_selected)?_c('vs-checkbox',{staticClass:"ml-0 mb-4 text-sm",model:{value:(_vm.useDefaultLanguageContent),callback:function ($$v) {_vm.useDefaultLanguageContent=$$v},expression:"useDefaultLanguageContent"}},[_vm._v(" "+_vm._s(this.$t('apps.campaigns.section_content_checkbox_default_language'))+" ")]):_vm._e()],1)]):_c('p',{staticClass:"text-right text-sm"},[_vm._v(_vm._s(_vm.content? _vm.content.count : 0)+" "+_vm._s(this.$t('apps.campaigns.section_content_devices')))]),_c('vs-textarea',{staticClass:"mb-2",class:{'bg-grey-light': _vm.useDefaultLanguageContent},attrs:{"disabled":_vm.useDefaultLanguageContent,"label":this.$t('apps.campaigns.section_content_input_title')},model:{value:(_vm.title),callback:function ($$v) {_vm.title=$$v},expression:"title"}}),(_vm.errorBag.title && !_vm.useDefaultLanguageContent)?_c('p',{staticClass:"text-danger text-xs"},[_vm._v(_vm._s(_vm.errorBag.title))]):_vm._e(),_c('p',{staticClass:"text-right text-xs"},[_c('span',{class:{
        'text-danger': _vm.titleLength > _vm.maxTitleLength,
        'text-success': _vm.titleLength <= _vm.maxTitleLength,
      }},[_vm._v(_vm._s(_vm.titleLength))]),_vm._v(" /"+_vm._s(_vm.maxTitleLength)+" "+_vm._s(this.$t('apps.campaigns.section_content_maximum_recommended_characters'))+" ")]),_c('vs-textarea',{staticClass:"mb-2",class:{'bg-grey-light': _vm.useDefaultLanguageContent},attrs:{"disabled":_vm.useDefaultLanguageContent,"label":this.$t('apps.campaigns.section_content_input_message'),"rows":"3"},model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}}),(_vm.errorBag.message && !_vm.useDefaultLanguageContent)?_c('p',{staticClass:"text-danger text-xs"},[_vm._v(_vm._s(_vm.errorBag.message))]):_vm._e(),_c('p',{staticClass:"text-right text-xs"},[_c('span',{class:{
        'text-danger': _vm.messageLength > _vm.maxMessageLength,
        'text-success': _vm.messageLength <= _vm.maxMessageLength,
      }},[_vm._v(_vm._s(_vm.messageLength))]),_vm._v(" /"+_vm._s(_vm.maxMessageLength)+" "+_vm._s(this.$t('apps.campaigns.section_content_maximum_recommended_characters'))+" ")]),(_vm.content && _vm.content.hasOwnProperty('url'))?_c('vs-input',{staticClass:"w-full mb-2",class:{'bg-grey-light': _vm.useDefaultLanguageContent},attrs:{"disabled":_vm.useDefaultLanguageContent,"type":"url","label":this.$t('apps.campaigns.section_content_input_url')},model:{value:(_vm.url),callback:function ($$v) {_vm.url=$$v},expression:"url"}}):_vm._e(),(_vm.errorBag.url && !_vm.useDefaultLanguageContent)?_c('p',{staticClass:"text-danger text-xs"},[_vm._v(_vm._s(_vm.errorBag.url))]):_vm._e(),(_vm.content && _vm.content.hasOwnProperty('image'))?_c('ImageForm',{staticClass:"w-full mt-4",attrs:{"id":("image-" + (this.languageAcronym)),"valid-sizes":[
      { width: '960', height: '740' }
    ],"validationWeight":"500","validationMessage":this.$t('apps.campaigns.section_content_validation_image_error'),"constraintsText":"Jpg 960x740px (max. 500Kb)","height":"250","src":_vm.image,"disabled":_vm.useDefaultLanguageContent},on:{"updateImage":_vm.handleUpdateImage}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"mb-4"},[_vm._v(_vm._s(this.$t('apps.campaigns.section_schedule_title')))]),_c('ul',[_c('li',{staticClass:"mt-2"},[_c('vs-radio',{attrs:{"vs-name":"schedule","vs-value":true},model:{value:(_vm.sendNow),callback:function ($$v) {_vm.sendNow=$$v},expression:"sendNow"}},[_vm._v(_vm._s(this.$t('apps.campaigns.section_schedule_radio_now')))])],1),_c('li',{staticClass:"mt-2"},[_c('vs-radio',{attrs:{"vs-name":"schedule","vs-value":false},model:{value:(_vm.sendNow),callback:function ($$v) {_vm.sendNow=$$v},expression:"sendNow"}},[_vm._v(_vm._s(this.$t('apps.campaigns.section_schedule_radio_program')))])],1)]),(!_vm.sendNow)?_c('div',[_c('flat-pickr',{attrs:{"placeholder":this.$t('apps.campaigns.section_schedule_datepicker_placeholder'),"config":{
          wrap: false,
          enableTime: true,
          time_24hr: true,
          defaultDate: _vm.DateTime.fromObject({zone: this.AppActiveUser.timezone}).toISO(),
          minDate: _vm.DateTime.fromObject({zone: this.AppActiveUser.timezone}).toISO(),
          minuteIncrement: 1
        }},model:{value:(_vm.scheduleDate),callback:function ($$v) {_vm.scheduleDate=$$v},expression:"scheduleDate"}}),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(_vm._s(this.$t('apps.campaigns.section_schedule_timezone'))+" "+_vm._s(this.AppActiveUser.timezone))])],1):_vm._e(),_c('div',{staticClass:"mt-8"},[_c('h5',{staticClass:"mb-4"},[_vm._v(_vm._s(this.$t('apps.campaigns.section_expiration_date_title'))+" "),_c('vx-tooltip',{staticClass:"ml-0 checkbox__tooltip cursor-pointer",attrs:{"text":this.$t('apps.campaigns.section_expiration_date_info_text'),"position":"top"}},[_c('vs-icon',{attrs:{"icon":"help","round":"","bg":"white","color":"primary"}})],1)],1),_c('div',[_c('v-select',{staticClass:"w-1/4",attrs:{"clearable":false,"options":_vm.campaigns.form_options.ttl},model:{value:(_vm.selectedTtlOption),callback:function ($$v) {_vm.selectedTtlOption=$$v},expression:"selectedTtlOption"}}),(_vm.selectedTtlOption.code === 'custom')?_c('div',[_c('flat-pickr',{staticClass:"mt-2",attrs:{"value":this.$store.getters['apps/campaign_expiration_date'],"config":{
            wrap: false,
            enableTime: true,
            time_24hr: true,
            minDate: _vm.minCalendarExpiryDate,
            maxDate: _vm.maxCalendarExpiryDate,
            hourIncrement: 1
          }},on:{"input":_vm.handleExpirationDateChange}}),_c('p',{staticClass:"mt-2 text-sm"},[_vm._v(_vm._s(this.$t('apps.campaigns.section_schedule_timezone'))+" "+_vm._s(this.AppActiveUser.timezone))])],1):_vm._e()],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }